import { render, staticRenderFns } from "./exchange-rate-widget.pug?vue&type=template&id=76514ab2&scoped=true&lang=pug&"
import script from "./exchange-rate-widget.js?vue&type=script&lang=js&"
export * from "./exchange-rate-widget.js?vue&type=script&lang=js&"
import style0 from "./exchange-rate-widget-content-placeholder.scss?vue&type=style&index=0&prod&lang=scss&"
import style1 from "./exchange-rate-widget.scss?vue&type=style&index=1&id=76514ab2&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76514ab2",
  null
  
)

export default component.exports